@import 'src/utils/utils';

.cookie {
  position: fixed;
  top: unset;
  left: unset;
  right: 0;
  bottom: 20px;
  left: unset;
  z-index: 15;
  width: unset;
  height: unset;
  padding-right: 16px;
  padding-left: 16px;
  color: inherit;
  pointer-events: none;
  overflow: initial;

  @media(min-width: $md) {
    right: 20px;
    bottom: 50px;
  }

  .button {
    width: 100%;
    margin-top: 20px;
  }

  &__overlay {
    display: none;
  }

  &__wrapper {
    position: relative;
    display: flex;
    max-width: 320px;
    justify-content: space-between;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 25px 40px rgba($color: #111111, $alpha: 20%);
    pointer-events: auto;

    @media(min-width: $md) {
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      border-radius: 15px;
    }
  }

  &__main {
    margin-right: 10px;
  }

  &__title {
    @include Text-18-bold;
    margin: 0;
    margin-bottom: 5px;
  }

  &__text {
    margin: 0;
    padding: 0;
    font: inherit;
    text-decoration: none;

    a {
      color: #cb333b;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.3s;
  
      &:hover {
        @media(min-width: $lg) {
          text-decoration-color: currentcolor;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 5;
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 50%;
    color: $Main-1;
    background-color: $Main-4;
    transition:
      transform 0.5s,
      color 0.3s;
    outline: none;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
        transform: rotate(90deg);
      }
    }

    span {
      position: relative;
      display: inline-block;
      width: 14px;
      height: 2px;
      background: transparent;
      vertical-align: bottom;

      @media(min-width: $md) {
        width: 16px;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentcolor;
        content: '';
        transform: rotate(45deg);

        @media(min-width: $md) {
          height: 2px;
        }
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentcolor;
        content: '';
        transform: rotate(-45deg);

        @media(min-width: $md) {
          height: 2px;
        }
      }
    }
  }
}